export const en = {
    translation: {
        // languages
        en: "English",
        fr: "French",
        // home and login
        send_sms: "Send SMS",
        sms_sent: "SMS has been sent",
        sms_failed_to_send: "SMS failed to send. Try again",
        login_or_register: "Login or create account",
        login_or_register_description: "Get access to your credit score for free anytime you want",
        phone_number: "Phone number",
        phone_number_working: "Enter your mobile number to sign up. Ensure it can receive SMS.",
        login_header: "Login",
        register_header: "Create an account to get your free credit report",
        login_button: "Login",
        register_button: "Create an account",
        input_sms_instruction: "Input the code received by sms",
        terms_and_conditions: "By clicking on create account, you agree to heypanda's terms of service, including our privacy policy",
        back: "Back",
        link_about: "About us",
        link_learn: "Learn",
        link_careers: "Careers",
        link_help: "Help",
        show_again: "Do not show again",

        // snackbar
        successful_update: "Successful update",

        // form
        welcome_header: "Congratulations!\nTalk to us about yourself",
        form_instructions:
            "Please answer these questions as truthfully as possible. It will help our AI create a personalized credit building or rebuilding plan for you",
        dropdown_label: "Select your choice",
        submit_button: "Submit",
        save_button: "Save",
        next_button: "Next step",
        step: "Step",

        // routes
        credit_cards_route: "Credit cards",
        personal_loans_route: "Personal loans",
        mortgages_route: "Mortgages",
        articles_route: "Articles",
        contact_route: "Contact",
        get_started_route: "Get Started",
        //edit_route: "Edit",
        profile_route: "Profile",
        logout_route: "Logout",
        home_route: "Home",

        // signup
        signup__title: "Sign up now for your free credit report",
        signup__desc:
            "Become a part of a large community and retrieve your no-cost credit score. Receive tailor-made advice, resources, and opportunities that align with your unique profile and aid you in achieving your financial objectives.",

        // footer
        footer_company_info: "Company info",
        footer_help: "Help",
        footer_legal: "Legal",
        footer_about_link: "About HeyPanda",
        footer_help_link: "Help Center",
        footer_security_link: "Security",
        footer_privacy_link: "Privacy Policy",
        footer_terms_link: "Terms of Services",

        // Home
        home_hi: "Hi",
        home_thankyou: "Thank you for registering successfully with HeyPanda!",
        home_copycode: "Copy Code",
        home_access:
            "With the provided code above, you will have access to the full CreditView experience, including monthly alerts which are critical for early detection of changes to personal information, credit file & score.",
        home_transunion: "Go to Transunion",
        home_features: "Features coming soon",
        home_close: "Close",
        home_modalCode: "Your code",

        // Edit
        edit_header: "Update your account",
        /*email*/
        edit_email_header: "Update your email address",
        edit_email_instructions: "We’ll send you an email confirming your email address has been updated.",
        edit_email_input: "New email",
        edit_email_input_reenter: "Re-enter new email",
        edit_email_password: "Verify your password",
        edit_email_button: "Update email",
        /*address*/
        edit_address_header: "Update your address info",
        edit_address_instructions: "We’ll send you an email confirming your email address has been updated.",
        edit_address_street: "What is your address",
        edit_address_province: "What is your province",
        edit_address_postal: "What is your postal code",
        edit_address_button: "Update adress",
        /*Communication*/
        edit_communication_header: "Communication preferences",
        edit_language: "Language preference",
        edit_approve_monitor: "Send me communications to help me monitor my credit and get the most out of my account",
        edit_approve_offers: "Send me communications with insights to help me make financial progress as well as special offers",
        /*Income*/
        edit_income_header: "Update my income",
        edit_income_instructions:
            "We use this info to show you personalized tips and options for your credit - like credit cards you might be eligible for",
        edit_income: "Annual income(before taxes)",
        edit_income_select: " Select your income range",
        edit_income_button: "Update my income",
        /*Other*/
        other_header: "Other account options",
        other_deactivate: "Deactivate my account",

        /*********** Questions ***********/
        email: "What is your email address?",
        reenter_email: "Re-enter new email",
        first_name: "What is your first name?",
        last_name: "What is your last name?",
        address: "What is your address?",
        province: "What is your province?",
        postal_code: "What is your postal code?",
        marrital_status: "Are you married common law or single?",
        career: "What type of job do you do?",
        income: "What is your annual net income?",
        home_or_rent: "Do you own a house or do you rent?",
        car_loan_or_lease: "Do you currently have a car loan or lease?",
        credit_cards: "Do you have any credit cards?",
        credit_cards_num: "How many?",
        gambling: "Have you ever tried online gaming platforms such as online casinos or sports book?",
        gambling_frequency: "Are you a regular player? (once a month)",
        gambling_amount: "What is the average amount of money you deposit for each gaming session?",
        payday_microloan: "Have you ever used a payday loans or a micro loan service?",
        payday_microloan_currently: "Are you currently using them?",
        language: "Select your language",

        /*********** ToolTips ***********/
        benefits: {
            smart_financial_choices: "Access your Transunion credit report for smarter financial decisions.",
            know_your_financial_health: "Get a clear picture of your financial health with your credit report.",
            budget_with_confidence: "Plan your budget confidently with insights from your credit report.",
            spot_inaccuracies: "Detect and correct inaccuracies in your credit history.",
            debt_management: "Use your credit report to manage and reduce debt effectively.",
            interest_rate_savings: "Improve your credit, secure better interest rates, and save money.",
            loan_approval: "Ensure higher chances of loan approval with a healthy credit report.",
            track_progress: "Monitor your credit journey with regular credit report checks.",
            identity_protection: "Detect potential identity theft by reviewing your credit history.",
            financial_clarity: "Gain clarity on your financial past and pave the way for a brighter future.",
            rental_applications: "Enhance your rental application with a strong credit report.",
            insurance_rates: "Improve your credit and potentially lower your insurance rates.",
            future_planning: "Use your credit report to plan for future financial goals.",
            credit_card_selection: "Choose the right credit cards based on your credit report insights.",
            interest_free_offers: "Qualify for interest-free financing offers with a good credit report.",
            landing_a_job: "Some employers check credit reports; ensure yours is in good shape.",
            mortgage_readiness: "Prepare for homeownership by monitoring your credit report.",
            avoid_costly_mistakes: "Avoid financial missteps by staying informed with your credit report.",
            better_negotiation: "Negotiate favorable terms with creditors using your credit report data.",
            peace_of_mind: "Enjoy peace of mind knowing you're making informed financial decisions.",
        },
        benefits_title: {
            smart_financial_choices: "Smart financial choices",
            know_your_financial_health: "Know your financial health",
            budget_with_confidence: "Budget with confidence",
            spot_inaccuracies: "Spot inaccuracies",
            debt_management: "Debt management",
            interest_rate_savings: "Interest rate savings",
            loan_approval: "Loan approval",
            track_progress: "Track progress",
            identity_protection: "Identity protection",
            financial_clarity: "Financial clarity",
            rental_applications: "Rental applications",
            insurance_rates: "Insurance rates",
            future_planning: "Future planning",
            credit_card_selection: "Credit card selection",
            interest_free_offers: "Interest free offers",
            landing_a_job: "Landing a job",
            mortgage_readiness: "Mortgage readiness",
            avoid_costly_mistakes: "Avoid coslty mistakes",
            better_negotiation: "Better negotiation",
            peace_of_mind: "Peace of mind",
        },
    },
};
