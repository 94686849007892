import { useTranslation } from "react-i18next";

export const extractFileName = (filename: string) => {
    let extractedFileName = "";

    if (decodeURIComponent(filename) && decodeURIComponent(filename).match(/(?<=\/)[^/?#]+(?=[^/]*$)/gi)) {
        const decodedElement = decodeURIComponent(filename).match(/(?<=\/)[^/?#]+(?=[^/]*$)/gi);

        if (decodedElement) {
            extractedFileName = decodedElement.toString();
        }
    }

    return extractedFileName;
};

export const divideArrayIntoSubsets = (array: any[], subsetSize: number[]) => {
    const subsets: any[] = [];

    const clonedArray = [...array];

    subsetSize.forEach(subSize => {
        const subset = clonedArray.slice(0, subSize);
        subsets.push(subset);
        clonedArray.splice(0, subSize);
    });

    return subsets;
};

export const convertToEnglish = (language: string, sentence: string) => {
    const { t } = useTranslation();
    if (language === "en") return sentence;
    else return t("setence");
};

// export const toTitleCase = (str: string) => {
//     return str
//         .split("_")
//         .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
//         .join(" ");
// };
