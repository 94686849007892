import React from "react";

// Mui
import LinearProgress from "@mui/material/LinearProgress";

const Loader: React.FC = () => (
    <div className="loader__container">
        <LinearProgress color="primary" />
    </div>
);

export default Loader;
