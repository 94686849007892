import { useState, useEffect, Dispatch, SetStateAction } from "react";

// Utils
import { divideArrayIntoSubsets } from "../utils/general";

// Interfaces
import { Question } from "../interfaces/Questions";

// Hooks
import { useTranslation } from "react-i18next";

interface Props {
    setQuestions: Dispatch<SetStateAction<Question[][] | undefined>>;
    editable?: boolean;
    subsets?: number[];
}

export const useHandleQuestions = ({ setQuestions, editable, subsets }: Props) => {
    // states
    const [language, setLanguage] = useState(localStorage.getItem("language"));
    // hooks
    const { i18n } = useTranslation();

    useEffect(() => {
        const handleStorageChange = (event: any) => {
            setLanguage(event);
        };
        i18n.on("languageChanged", handleStorageChange);

        return () => i18n.off("languageChanged", handleStorageChange);
    }, []);

    useEffect(() => {
        handleLanguageOfQuestions();
    }, [language]);

    const handleLanguageOfQuestions = async () => {
        let questions: Question[] = [];

        switch (language) {
            case "en":
                await import("../data/questions").then(module => {
                    questions = module.enQuestions;
                });
                break;
            case "fr":
                await import("../data/questions").then(module => {
                    questions = module.frQuestions;
                });
                break;
        }

        if (editable)
            questions = questions.filter(question => {
                return question?.editable || question?.editFormOnly;
            });
        else
            questions = questions.filter(question => {
                return !question?.editFormOnly;
            });

        if (subsets) setQuestions(divideArrayIntoSubsets(questions, subsets));
        else setQuestions(divideArrayIntoSubsets(questions, [questions.length]));
    };
    return { language, setLanguage };
};
