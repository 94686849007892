import { createTheme } from "@mui/material/styles";

// Assets
import colors from "../scss/base/_themes-vars.module.scss";

// Custom components
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";

export const theme = () => {
    const color = colors;
    const outfit = "Outfit, sans-serif";

    const themeOption = {
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.primaryLight,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: color.secondaryDark,
        menuSelectedBack: color.secondaryLight,
        divider: color.grey200,
        customization: {
            fontFamily: outfit,
        },
    };

    const themeOptions: any = {
        direction: "ltr",
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: "48px",
                padding: "16px",
                "@media (min-width: 600px)": {
                    minHeight: "48px",
                },
            },
        },
        typography: themeTypography(themeOption),
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
