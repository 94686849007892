import { IonButton, IonContent, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonSelect, IonSelectOption } from "@ionic/react";
import React, { useRef, useState } from "react";

// Firebase
import { auth, firestore } from "../../../firebase/firebase";
import { updateDoc, doc } from "firebase/firestore";

// Hooks
import { useTranslation } from "react-i18next";
import { useFetchUser } from "../../../hooks/useFetchUser";
import { useHandleQuestions } from "../../../hooks/useHandleLanguage";
import { useSnackbar } from "notistack";

// Interfaces
import { Question } from "../../../interfaces/Questions";

interface Path {
    name: string;
    url: string;
}

const Menu: React.FC = () => {
    const menuRef = useRef(null);

    // States
    const [partionedQuestions, setPartionedQuestions] = useState<Question[][]>();

    const { t, i18n } = useTranslation();
    const { currentUser, setLoading } = useFetchUser();
    const { language, setLanguage } = useHandleQuestions({ setQuestions: setPartionedQuestions, editable: true, subsets: [2, 3, 1, 1] });
    const { enqueueSnackbar } = useSnackbar();

    // OnChange functions
    const handleLogout = () => {
        auth.signOut();
    };

    const handleLanguageSubmit = async (e: any) => {
        e.preventDefault();

        const lang = e.target.value;
        setLanguage(lang);
        i18n.changeLanguage(lang);
        localStorage.setItem("language", lang);
        if (menuRef.current) {
            (menuRef.current as any).close();
        }

        if (!currentUser) return;

        try {
            setLoading(true);
            const userDocRef = doc(firestore, "Users", currentUser.id);
            await updateDoc(userDocRef, { language: lang });
            enqueueSnackbar(t("successful_update"), { variant: "success" });
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Error", { variant: "error" }); //Needs descriptive message here
        } finally {
            setLoading(false);
        }
    };

    const paths: Path[] = [
        { name: t("get_started_route"), url: "/" },
        { name: t("profile_route"), url: "/edit" },
        // { name: t("contact_route"), url: "/app/contact" },
        // { name: t("articles_route"), url: "/app/articles" },
        // { name: t("mortgages_route"), url: "/app/mortgages" },
        // { name: t("personal_loans_route"), url: "/app/personalLoans" },
        // { name: t("credit_cards_route"), url: "/app/creditCards" },
    ];
    // if (!currentUser || (currentUser && !currentUser?.formCompleted)) return <></>;
    return (
        <IonMenu contentId="main" type="overlay" side="end" ref={menuRef}>
            <IonContent>
                <IonList>
                    {currentUser &&
                        currentUser.formCompleted &&
                        paths &&
                        paths.map((path, index) => {
                            return (
                                <IonMenuToggle key={index} autoHide={false}>
                                    <IonItem routerLink={path.url} routerDirection="none" detail={false}>
                                        <IonLabel>{path.name}</IonLabel>
                                    </IonItem>
                                </IonMenuToggle>
                            );
                        })}
                    <IonItem>
                        <IonLabel>
                            <a className="menu__link" href="https://heypanda.com/about-us/" target="_blank">
                                {t("link_about")}
                            </a>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <a className="menu__link" href="https://heypanda.com/learn/" target="_blank">
                                {t("link_learn")}
                            </a>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <a className="menu__link" href="https://heypanda.com/careers/" target="_blank">
                                {t("link_careers")}
                            </a>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <a className="menu__link" href="https://heypanda.com/help/" target="_blank">
                                {t("link_help")}
                            </a>
                        </IonLabel>
                    </IonItem>
                    {partionedQuestions && (
                        <IonItem>
                            <IonSelect
                                color={"tertiary"}
                                className="text__input ionSelect__menu"
                                labelPlacement="floating"
                                label={localStorage.getItem("language") === "en" ? "Select your language" : "Sélectionner votre langue"}
                                value={language}
                                onIonChange={handleLanguageSubmit}
                            >
                                {partionedQuestions[3][0]?.values &&
                                    partionedQuestions[3][0].values.map((option: any, index) => (
                                        <IonSelectOption key={index} value={option}>
                                            {option}
                                        </IonSelectOption>
                                    ))}
                            </IonSelect>
                        </IonItem>
                    )}
                    {currentUser && (
                        <IonMenuToggle>
                            <IonButton expand="full" onClick={handleLogout}>
                                {t("logout_route")}
                            </IonButton>
                        </IonMenuToggle>
                    )}
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
