import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./locale/en";
import { fr } from "./locale/fr";
import moment from "moment";
import "moment/locale/fr";

const resources = {
    en,
    fr,
};

let language = localStorage.getItem("language");
if (!language) {
    localStorage.setItem("language", "en"); //Need default value in ls for Questions
    language = "en";
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: language,
        fallbackLng: ["en", "fr"],
        //keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

moment.locale(i18n.language);

export default i18n;
