import serviceConfig from "./firebaseConfig.json";

import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFirestore } from "firebase/firestore";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const app = initializeApp(serviceConfig);

if (process.env.NODE_ENV === "development") {
    // Prints appcheck debug token in the console
    (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

// initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider("6Lcg1vIiAAAAAJ6UGG9UYTZQ_tWhqEDoTNqiQQmo"),
//     isTokenAutoRefreshEnabled: true,
// });

export const auth = getAuth();
export const firestore = getFirestore();
export const storage = getStorage();
export const functions = getFunctions();

// (window as any).recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {}, auth);
