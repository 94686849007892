export const fr = {
    translation: {
        // languages
        en: "Anglais",
        fr: "Français",

        // home and login
        send_sms: "Envoyer un SMS",
        sms_sent: "Le SMS a été envoyé",
        sms_failed_to_send: "Échec de l'envoi du SMS. Essayer à nouveau",
        login_or_register: "Connectez-vous ou créez un compte",
        login_or_register_description: "Accédez à votre pointage de crédit gratuitement quand vous le souhaitez",
        phone_number: "Numéro de téléphone",
        phone_number_working: "Entrez votre numéro de téléphone portable pour créer un compte. Assurez-vous qu'il peut recevoir des SMS.",
        login_header: "Connexion",
        register_header: "Créez un compte pour obtenir votre dossier de crédit gratuit",
        login_button: "Connexion",
        register_button: "Créer un compte",
        input_sms_instruction: "Saisissez le code reçu par sms",
        terms_and_conditions:
            "En cliquant sur créer un compte, vous acceptez les conditions d'utilisation de heypanda, y compris notre politique de confidentialité",
        back: "Retour",
        link_about: "À propos de nous",
        link_learn: "Apprendre",
        link_careers: "Carrières",
        link_help: "Aide",
        show_again: "Ne plus afficher",

        // snackbar
        successful_update: "Mise à jour réussie",

        // form
        welcome_header: "Toutes nos félicitations! Parlez-nous de vous",
        form_instructions:
            "Veuillez répondre à ces questions aussi sincèrement que possible. Cela aidera notre IA à créer un plan personnalisé de construction ou de reconstruction de crédit pour vous",
        dropdown_label: "Sélectionnez votre choix",
        submit_button: "Envoyer",
        save_button: "Sauvegarder",
        next_button: "Prochaine étape",
        step: "Étape",

        //routes
        credit_cards_route: "Cartes de crédit",
        personal_loans_route: "Prêts personnels",
        mortgages_route: "Hypothèques",
        articles_route: "Articles",
        contact_route: "Contact",
        get_started_route: "Commencer",
        //edit_route: "Modifier",
        profile_route: "Profil",
        logout_route: "Déconnecter",
        home_route: "Page d'accueil",

        // signup
        signup__title: "Inscrivez-vous maintenant pour obtenir votre rapport de solvabilité gratuit",
        signup__desc:
            "Faites partie d'une grande communauté et récupérez votre score de crédit sans frais. Recevez des conseils, des ressources et des opportunités sur mesure qui correspondent à votre profil unique et vous aident à atteindre vos objectifs financiers.",

        // footer
        footer_company_info: "Information d'entreprise",
        footer_help: "Aide",
        footer_legal: "Juridique",
        footer_about_link: "À propos de HeyPanda",
        footer_help_link: "Centre d'aide",
        footer_security_link: "Sécurité",
        footer_privacy_link: "Politique de confidentialité",
        footer_terms_link: "Conditions d'utilisation",

        // Home
        home_hi: "Bonjour",
        home_thankyou: "Merci de vous être bien enregistré avec HeyPanda!",
        home_copycode: "Copier le code",
        home_access:
            "Avec le code fourni ci-dessus, vous aurez accès à l'expérience complète de CreditView, y compris des alertes mensuelles qui sont cruciales pour la détection précoce des changements dans les informations personnelles, le dossier de crédit et le score de crédit.",
        home_transunion: "Accéder à Transunion",
        home_features: "Fonctionnalitées à venir",
        home_close: "Fermer",
        home_modalCode: "Votre code",

        // edit
        edit_header: "Mettre à jour votre compte",
        /*email*/
        edit_email_header: "Mettre à jour votre adresse e-mail",
        edit_email_instructions: "Nous vous enverrons un e-mail confirmant la mise à jour de votre adresse e-mail.",
        edit_email_input: "Nouvelle adresse e-mail",
        edit_email_input_reenter: "Confirmez la nouvelle adresse e-mail",
        edit_email_password: "Vérifiez votre mot de passe",
        edit_email_button: "Mettre à jour l'e-mail",
        /*address*/
        edit_address_header: "Mettre à jour vos informations d'adresse",
        edit_address_instructions: "Nous vous enverrons un e-mail confirmant la mise à jour de vos informations d'adresse.",
        edit_address_street: "Quelle est votre adresse",
        edit_address_province: "Quelle est votre province",
        edit_address_postal: "Quel est votre code postal",
        edit_address_button: "Mettre à jour l'adresse",
        /*Communication*/
        edit_communication_header: "Préférences de communication",
        edit_language: "Préférence de langue",
        edit_approve_monitor: "envoyez-moi des communications pour m'aider à surveiller mon crédit et tirer le meilleur parti de mon compte",
        edit_approve_offers:
            "envoyez-moi des communications avec des informations pour m'aider à progresser financièrement ainsi que des offres spéciales",
        /*Income*/
        edit_income_header: "Mettre à jour mes revenus",
        edit_income_instructions:
            "Nous utilisons ces informations pour vous montrer des conseils personnalisés et des options pour votre crédit - comme les cartes de crédit auxquelles vous pourriez être éligible",
        edit_income: "Revenu annuel (avant impôts)",
        edit_income_select: "Sélectionnez votre fourchette de revenus",
        edit_income_button: "Mettre à jour mes revenus",
        /*Other*/
        other_header: "Autres options de compte",
        other_deactivate: "Désactiver mon compte",

        /**********************************************************************Questions******************************************************************************************************/
        // questions
        "Quelle est votre adresse e-mail?": "What is your email address?",
        "Réentrez votre nouvel email": "Re-enter new email",
        "Quel est votre prénom?": "What is your first name?",
        "Quel est votre nom de famille?": "What is your last name?",
        "Quelle est votre adresse?": "What is your address?",
        "Quelle est votre province?": "What is your province?",
        "Quel est votre code postal?": "What is your postal code?",
        "Êtes-vous marié(e), en union de fait ou célibataire?": "Are you married common law or single?",
        "Quel type de travail effectuez-vous?": "What type of job do you do?",
        "Quel est votre revenu net annuel?": "What is your annual net income?",
        "Possédez-vous une maison ou louez-vous?": "Do you own a house or do you rent?",
        "Avez-vous actuellement un prêt ou une location de voiture?": "Do you currently have a car loan or lease?",
        "Avez-vous des cartes de crédit?": "Do you have any credit cards?",
        "Combien en avez-vous?": "How many?",
        "Avez-vous déjà essayé des plateformes de jeux en ligne telles que les casinos en ligne ou les sites de paris sportifs?":
            "Have you ever tried online gaming platforms such as online casinos or sports book?",
        "Êtes-vous un joueur régulier? (une fois par mois)": "Are you a regular player? (once a month)",
        "Quel est le montant moyen que vous déposez lors de chaque session de jeu?":
            "What is the average amount of money you deposit for each gaming session?",
        "Avez-vous déjà utilisé des prêts sur salaire ou un service de micro-prêt?": "Have you ever used a payday loans or a micro loan service?",
        "Les utilisez-vous actuellement?": "Are you currently using them?",
        "Sélectionnez votre langue": "Select your language",

        /*********** Questions ***********/
        email: "Quelle est votre adresse e-mail?",
        reenter_email: "Retapez la nouvelle adresse e-mail",
        first_name: "Quel est votre prénom?",
        last_name: "Quel est votre nom de famille?",
        address: "Quelle est votre adresse?",
        province: "Quelle est votre province?",
        postal_code: "Quel est votre code postal?",
        marrital_status: "Êtes-vous marié(e) ou en union libre?",
        career: "Quel est votre type d'emploi?",
        income: "Quel est votre revenu net annuel?",
        home_or_rent: "Possédez-vous une maison ou louez-vous?",
        car_loan_or_lease: "Avez-vous actuellement un prêt auto ou une location?",
        credit_cards: "Possédez-vous des cartes de crédit?",
        credit_cards_num: "Combien en avez-vous?",
        gambling: "Avez-vous déjà essayé des plateformes de jeux en ligne telles que des casinos en ligne ou des paris sportifs?",
        gambling_frequency: "Êtes-vous un joueur régulier? (une fois par mois)",
        gambling_amount: "Quel est le montant moyen que vous déposez lors de chaque session de jeu?",
        payday_microloan: "Avez-vous déjà utilisé des prêts sur salaire ou des services de microcrédit?",
        payday_microloan_currently: "Les utilisez-vous actuellement?",
        language: "Sélectionnez votre langue",

        // values // Only need values section for languages that are not English since they are saved in English in Firebase
        Alberta: "Alberta",
        "Colombie-Britannique": "British Columbia",
        Manitoba: "Manitoba",
        "Nouveau-Brunswick": "New Brunswick",
        "Terre-Neuve-et-Labrador": "Newfoundland and Labrador",
        "Territoires du Nord-Ouest": "Northwest Territories",
        "Nouvelle-Écosse": "Nova Scotia",
        Nunavut: "Nunavut",
        Ontario: "Ontario",
        "Île-du-Prince-Édouard": "Prince Edward Island",
        Québec: "Quebec",
        Saskatchewan: "Saskatchewan",
        Yukon: "Yukon",
        //
        "Marié(e)": "Married",
        "Union de fait": "Common-law",
        Célibataire: "Single",
        //
        "Quelque chose": "Something",
        "Autre chose": "Something else",
        //
        Propriétaire: "Own",
        Locataire: "Rent",
        //
        "Employé de bureau": "Office Worker",
        "Vente au détail": "Retail",
        "Soins de santé": "Healthcare",
        Éducation: "Education",
        "TI/Technologie": "IT/Technology",
        Finance: "Finance",
        "Arts/Divertissement": "Arts/Entertainment",
        "Industrie de services": "Service Industry",
        Ingénierie: "Engineering",
        Entrepreneur: "Entrepreneur",
        Étudiant: "Student",
        "Homme au foyer": "Homemaker",
        "Sans emploi": "Unemployed",
        Autre: "Other",
        //conditional on
        Oui: "Yes",
        Non: "No",

        /*********** ToolTips ***********/
        benefits: {
            smart_financial_choices: "Accédez à votre rapport de crédit Transunion pour des décisions financières éclairées.",
            know_your_financial_health: "Obtenez une image claire de votre santé financière grâce à votre rapport de crédit.",
            budget_with_confidence: "Planifiez votre budget en toute confiance avec les informations de votre rapport de crédit.",
            spot_inaccuracies: "Détectez et corrigez les inexactitudes dans votre historique de crédit.",
            debt_management: "Utilisez votre rapport de crédit pour gérer et réduire efficacement votre dette.",
            interest_rate_savings: "Améliorez votre crédit, obtenez de meilleurs taux d'intérêt et économisez de l'argent.",
            loan_approval: "Augmentez vos chances d'approbation de prêt avec un rapport de crédit sain.",
            track_progress: "Surveillez votre parcours de crédit grâce à des vérifications régulières de votre rapport.",
            identity_protection: "Détectez les éventuels vols d'identité en examinant votre historique de crédit.",
            financial_clarity: "Obtenez une clarté sur votre passé financier et ouvrez la voie à un avenir plus radieux.",
            rental_applications: "Améliorez votre demande de location avec un rapport de crédit solide.",
            insurance_rates: "Améliorez votre crédit et potentiellement réduisez vos taux d'assurance.",
            future_planning: "Utilisez votre rapport de crédit pour planifier vos objectifs financiers futurs.",
            credit_card_selection: "Choisissez les bonnes cartes de crédit en fonction des informations de votre rapport de crédit.",
            interest_free_offers: "Qualifiez-vous pour des offres de financement sans intérêt avec un bon rapport de crédit.",
            landing_a_job: "Certains employeurs vérifient les rapports de crédit ; assurez-vous que le vôtre est en bon état.",
            mortgage_readiness: "Préparez-vous à devenir propriétaire en surveillant votre rapport de crédit.",
            avoid_costly_mistakes: "Évitez les erreurs financières en restant informé grâce à votre rapport de crédit.",
            better_negotiation: "Négociez des conditions favorables avec les créanciers en utilisant les données de votre rapport de crédit.",
            peace_of_mind: "Profitez de la tranquillité d'esprit en sachant que vous prenez des décisions financières éclairées.",
        },
        benefits_title: {
            smart_financial_choices: "Des choix financiers judicieux",
            know_your_financial_health: "Connaître sa santé financière",
            budget_with_confidence: "Confiant de votre budget",
            spot_inaccuracies: "Repérer les erreurs",
            debt_management: "Gestion de la dette",
            interest_rate_savings: "Taux d'intérêt",
            loan_approval: "Approbation du prêt",
            track_progress: "Suivez votre progression",
            identity_protection: "Protection de votre identité",
            financial_clarity: "Clarité financiare",
            rental_applications: "Demandes de location",
            insurance_rates: "Taux d'assurance",
            future_planning: "Planification du futur",
            credit_card_selection: "Sélection de carte de crédit",
            interest_free_offers: "Offre sans intérêt",
            landing_a_job: "Nouveau travail",
            mortgage_readiness: "Préparation à l'hypothèque",
            avoid_costly_mistakes: "Grosses erreurs",
            better_negotiation: "Mieux négocier",
            peace_of_mind: "Paix d'esprit",
        },
    },
};
