import ReactFP from "react-facebook-pixel";
import ReactGA from "react-ga4";

const sendEvent = (eventName, event) => {
    try {
        ReactGA.event(eventName, event);
        ReactFP.trackCustom(eventName, event);
    } catch (e) {
        console.error(e);
    }
};

export default {
    init: () => {
        ReactFP.init("1955876441450451");
        ReactGA.initialize([
            {
                trackingId: "GTM-TNTTMBND",
            },
        ]);
    },
    user_created: () => {
        const eventName = "user_created";
        const event = {};

        sendEvent(eventName, event);
    },
    onboarding_finished: () => {
        const eventName = "onboarding_finished";
        const event = {};

        sendEvent(eventName, event);
    },
};
