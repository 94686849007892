import { createRef, lazy } from "react";

// React-router-dom
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";

// Settings
import versionObj from "./version.json";

// Styles
import "./scss/App.scss";

// Ionic
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

// Utils
import settings from "./settings.json";
import { isMobile } from "./settings";

// Mui
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// Hooks
import { useFetchUser } from "./hooks/useFetchUser";
import { SnackbarProvider } from "notistack";
import { useTranslation } from "react-i18next";

// Custom components
import NavigationScroll from "./components/layout/NavigationScroll";
import Loadable from "./components/general/Loadable";
import themes from "./themes";
import Menu from "./components/layout/MainLayout/Menu";
import analytics from "./services/analytics";

// Pages
const HeaderAndFooter = Loadable(lazy(() => import("./components/layout/MainLayout/HeaderAndFooter")));

//// Header routes
const Edit = Loadable(lazy(() => import("./components/pages/Edit")));
const Home = Loadable(lazy(() => import("./components/pages/Home")));
const UserLogin = Loadable(lazy(() => import("./components/pages/auth/UserLogin")));
const Forms = Loadable(lazy(() => import("./components/general/Forms")));

export const PublicRoute = ({ title, path, exact = true, Component }: any) => {
    document.title = `${title} - ${settings.app.title}`;
    return (
        <Route path={path} exact={exact}>
            <Component />
        </Route>
    );
};

export const PrivateRoute = ({ title, path, exact = true, Component }: any) => {
    document.title = `${title} - ${settings.app.title}`;

    const { currentUser } = useFetchUser();

    // Hack-ish code to return you to the page you were before refresh
    if (!currentUser) {
        if (!window.location.href.includes("/login")) return <Redirect to="/login" />;
    }

    return currentUser ? (
        <Route path={path} exact={exact}>
            <Component />
        </Route>
    ) : (
        <Redirect to="/login" />
    );
};

const Routes = () => {
    const { t } = useTranslation();
    return (
        <Switch>
            {/* <Route
                path="/creditCards"
                component={() => (
                    <PrivateRoute title={t("credit_cards_route")}>
                        <CreditCards />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/personalLoans"
                component={() => (
                    <PrivateRoute title={t("personal_loans_route")}>
                        <PersonalLoans />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/mortgages"
                component={() => (
                    <PrivateRoute title={t("mortgages_route")}>
                        <Mortgages />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/articles"
                component={() => (
                    <PrivateRoute title={t("articles_route")}>
                        <Articles />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/contact"
                component={() => (
                    <PrivateRoute title={t("contact_route")}>
                        <Contact />
                    </PrivateRoute>
                )}
            /> */}
            <PrivateRoute path="/edit" Component={Edit} title={t("profile_route")} />
            <PrivateRoute path="/forms" Component={Forms} title="forms" />
            <PublicRoute path="/login" Component={UserLogin} title="login" />
            <PrivateRoute path="/" Component={Home} title="home" />

            <Redirect to="/login" />
        </Switch>
    );
};

setupIonicReact();

const App: React.FC<any> = () => {
    analytics.init();

    console.log(`%cVersion: ${versionObj && versionObj.version}`, "color: #bada55");

    // Hooks
    const snackbarRef: any = createRef();

    if (isMobile) {
        return (
            <IonApp>
                <SnackbarProvider
                    className="snackbar__container"
                    ref={snackbarRef}
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    autoHideDuration={2000}
                >
                    <IonReactRouter>
                        {/* <IonSplitPane contentId="main"> */}
                        <Menu />
                        <IonRouterOutlet id="main">
                            <Routes />
                        </IonRouterOutlet>
                        {/* </IonSplitPane> */}
                    </IonReactRouter>
                </SnackbarProvider>
            </IonApp>
        );
    }

    return (
        <StyledEngineProvider injectFirst>
            <SnackbarProvider
                className="snackbar__container"
                ref={snackbarRef}
                maxSnack={3}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                autoHideDuration={2000}
            >
                <ThemeProvider theme={themes()}>
                    <CssBaseline />
                    <NavigationScroll>
                        <BrowserRouter>
                            <HeaderAndFooter>
                                <Routes />
                            </HeaderAndFooter>
                        </BrowserRouter>
                    </NavigationScroll>
                </ThemeProvider>
            </SnackbarProvider>
        </StyledEngineProvider>
    );
};

export default App;

/*        <Router>
                                <Route
                                    path="/"
                                    element={
                                        <PublicRoute>
                                            <HeaderAndFooter>
                                                <Main />
                                            </HeaderAndFooter>
                                        </PublicRoute>
                                    }
                                />

                                <Route
                                    path="/creditCards"
                                    element={
                                        <PublicRoute title={t("credit_cards_route")}>
                                            <HeaderAndFooter>
                                                <CreditCards />
                                            </HeaderAndFooter>
                                        </PublicRoute>
                                    }
                                />

                                <Route
                                    path="/personalLoans"
                                    element={
                                        <PublicRoute title={t("personal_loans_route")}>
                                            <HeaderAndFooter>
                                                <PersonalLoans />
                                            </HeaderAndFooter>
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/mortgages"
                                    element={
                                        <PublicRoute title={t("mortgages_route")}>
                                            <HeaderAndFooter>
                                                <Mortgages />
                                            </HeaderAndFooter>
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/articles"
                                    element={
                                        <PublicRoute title={t("articles_route")}>
                                            <HeaderAndFooter>
                                                <Articles />
                                            </HeaderAndFooter>
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/contact"
                                    element={
                                        <PublicRoute title={t("contact_route")}>
                                            <HeaderAndFooter>
                                                <Contact />
                                            </HeaderAndFooter>
                                        </PublicRoute>
                                    }
                                />

                                <Route
                                    path="/edit"
                                    element={
                                        <PublicRoute title={t("profile_route")}>
                                            <HeaderAndFooter>
                                                <Edit />
                                            </HeaderAndFooter>
                                        </PublicRoute>
                                    }
                                />

                                <Route
                                    path="users"
                                    element={
                                        <PrivateRoute title="" rolesAllowed={["superAdmin", "admin"]}>
                                            <MainLayout>
                                                <Users />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route path="user">
                                    <Route path=":id">
                                        <Route
                                            path=":index"
                                            element={
                                                <PrivateRoute title="Utilisateur" rolesAllowed={["superAdmin", "admin"]}>
                                                    <MainLayout>
                                                        <User />
                                                    </MainLayout>
                                                </PrivateRoute>
                                            }
                                        />
                                    </Route>
                                </Route>

                                <Route
                                    path="logs"
                                    element={
                                        <PrivateRoute title="Journaux" rolesAllowed={["superAdmin", "admin"]}>
                                            <MainLayout>
                                                <Logs />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="login"
                                    element={
                                        <PublicRoute title="Connexion">
                                            <MinimalLayout>
                                                <Login />
                                            </MinimalLayout>
                                        </PublicRoute>
                                    }
                                />

                               <Route
                                    path="user-login"
                                    element={
                                        <PublicRoute title="Connexion">
                                            <MinimalLayout>
                                                <UserLogin />
                                            </MinimalLayout>
                                        </PublicRoute>
                                    }
                                /> 
                                <Route
                                    path="register"
                                    element={
                                        <PublicRoute title="Inscription">
                                            <MinimalLayout>
                                                <Register />
                                            </MinimalLayout>
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/forgotPassword"
                                    element={
                                        <PublicRoute title="Mot de passe oublié">
                                            <ForgotPassword />
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/changePassword"
                                    element={
                                        <PublicRoute title="Changement de mot de passe">
                                            <ChangePassword />
                                        </PublicRoute>
                                    }
                                />
                                <Route path="*" element={<Login />} />
                            </Router> */
